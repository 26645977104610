<template>
  <div>
    <Carousel arrow="always" v-model="value1" radius-dot easing>
      <CarouselItem>
        <div class="imgone">
          <img src="../assets/image/banner_background.jpg" alt="" />
          <div class="imgone-left">
            <div class="biaoyu">
              <div class="line2"></div>
              一年四次测评 <span>&nbsp;</span> 远离骨骼变形
            </div>
            <div class="box">
              <div class="line1"></div>
              <h1>关注儿童骨骼发育</h1>
              <h2>百万数据显示38.5%儿童存在骨骼发育问题</h2>
              <h5>随着城市化进程的推进，人们的经济条件越来越好，</h5>
              <h5>但是随之而来的是我们的生活环境也发生了变化，</h5>
              <h5>
                在日常生活中电子产品的普及，低头玩手机，躺着看电视，或者光脚走路等这些习惯都在时刻影响着孩子的骨骼发育。
              </h5>
              <div class="btn" @click="liaojie">
                了解详情<span>
                  <img src="../assets/image/icon_jr.png" alt="" />
                </span>
              </div>
            </div>
          </div>

          <div class="imgone-right">
            <div class="imgone-img">
              <img src="../assets/image/img_boy.png" alt="" />
              <div class="jingbu">
                <p>颈部发育</p>
                <p class="dap">17.5%</p>
                <p>存在问题</p>
              </div>
              <div class="qugan">
                <p>躯干发育</p>
                <p class="dap">20.8%</p>
                <p>存在问题</p>
              </div>
              <div class="gupen">
                <p>骨盆发育</p>
                <p class="dap">21.7%</p>
                <p>存在问题</p>
              </div>
              <div class="jizhu">
                <p>脊柱发育</p>
                <p class="dap">18.3%</p>
                <p>存在问题</p>
              </div>
              <div class="xiguanjie">
                <p>膝关节发育</p>
                <p class="dap">25.3%</p>
                <p>存在问题</p>
              </div>
              <div class="zubu">
                <p>足部发育</p>
                <p class="dap">35.3%</p>
                <p>存在问题</p>
              </div>
            </div>
          </div>
        </div>
      </CarouselItem>
      <CarouselItem>
        <div class="imgtwo">
          <img src="../assets/image/banner_background.jpg" alt="" />
          <div class="imgtwo-left">
            <div class="biaoyu">
              <div class="line2"></div>
              一年四次测评 <span>&nbsp;</span> 远离骨骼变形
            </div>
            <div class="box">
              <div class="line1"></div>
              <h1>为什么要骨骼测评</h1>
              <h2>通过骨骼测评，可尽早发现孩子骨骼发育问题</h2>
              <h5>
                儿童时期是孩子生长发育的黄金周期，这一时期孩子的骨骼可塑性极强，同时也极易变形，所以家长需要格外关注
              </h5>
              <h5>
                孩子的骨骼发育，定期测评，了解孩子的发育现状，一旦孩子存在任何骨骼发育问题，可尽早干预矫正，让孩子拥有
              </h5>
              <h5>一个完美的身体形态和健康的身体，是骨骼测评的最终目的。</h5>
              <div class="btn" @click="liaojie">
                了解详情<span>
                  <img src="../assets/image/icon_jr.png" alt="" />
                </span>
              </div>
            </div>
          </div>

          <div class="imgtwo-right">
            <div class="imgtwo-img">
              <img src="../assets/image/img_sjk.png" alt="" />
              <div class="jingbu">
                <p class="dap">了解孩子发育现状</p>
                <p>
                  进行全面的骨骼测评可清晰了解到孩子<br />现阶段的骨骼发育情况
                </p>
              </div>
              <div class="genzong">
                <p class="dap">持续跟踪<br />关注孩子的骨骼发育</p>
                <p>
                  骨骼发育是一个持续的过程 儿童和青少年时期<br />
                  骨骼发育速度最快，定期更新发育数据是防患<br />于未然的最好的方法<br />
                </p>
              </div>
              <div class="gugefayu">
                <p class="dap">推荐合适的运动或产品</p>
                <p>
                  每个孩子的骨骼测评数据都不相同，根据这些数据<br />
                  我们推荐适合每一个孩子的运动建议或矫形产品
                </p>
              </div>
              <div class="jizao">
                <p class="dap">及早发现骨骼发育问题</p>
                <p>
                  骨骼发育影响孩子的一生，全面的骨骼测评可以<br />
                  及早发现潜在问题，并及时进行干预
                </p>
              </div>
            </div>
          </div>
        </div>
      </CarouselItem>
    </Carousel>
    <div class="home-btn">
      <p>地址：陕西省西安市未央区经开万科中心15层 | 电话：029-6888-2888</p>

      
      <div style="width: 400px; margin: 0 auto; padding: 20px 0">
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=61010402000448"
          style="
            display: inline-block;
            text-decoration: none;
            height: 20px;
            line-height: 20px;
          "
          ><img src="../assets/image/BA.png" style="float: left" />
          <p
            style="
              float: left;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
              color: #939393;
            "
          >
            陕公网安备 61010402000448号
          </p></a
        >
        <a
          style="
            display: inline-block;
            text-decoration: none;
            height: 20px;
            line-height: 20px;
          "
          href="https://beian.miit.gov.cn/"
        >
          <p
            style="
              float: left;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
              color: #939393;
            "
          >
            陕ICP备18005906-1
          </p></a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value1: 0,
    };
  },
  methods: {
    liaojie() {
      this.$router.push("/shouye/test");
    },
  },
};
</script>

<style lang="scss" scoped>
.imgone {
  width: 100%;
  position: relative;
  .imgone-left {
    position: absolute;
    left: 100px;
    top: 50%;
    transform: translateY(-50%);
    width: 41%;
    height: 560px;
    z-index: 10;
    border-left: 2px solid hsla(0, 0%, 100%, 0.3);
    border-top: 2px solid hsla(0, 0%, 100%, 0.3);
    border-bottom: 2px solid hsla(0, 0%, 100%, 0.3);
    .biaoyu {
      position: relative;
      width: 12px;
      height: auto;
      font-size: 12px;
      font-weight: 400;
      font-stretch: normal;
      color: hsla(0, 0%, 100%, 0.5);
      float: left;
      text-align: center;
      margin-left: 40px;
      margin-top: 123px;
      .line2 {
        display: inline-block;
        width: 2px;
        height: 62px;
        background: hsla(0, 0%, 100%, 0.5);
      }
      span {
        display: inline-block;
        width: 12px;
        height: 12px;
      }
    }
    .box {
      position: relative;
      margin-top: 60px;
      margin-left: 95px;

      .line1 {
        width: 64px;
        height: 6px;
        background: #fff;
      }
      h1 {
        width: 100%;
        height: 66px;

        font-size: 3.3vw;
        font-stretch: normal;
        letter-spacing: 12px;
        color: #fdfefa;
        font-weight: bolder;
        margin-top: 45px;
      }
      h2 {
        width: 100%;
        height: 30px;

        font-size: 1.5vw;
        font-weight: 400;
        font-stretch: normal;
        letter-spacing: 2px;
        color: #fdfefa;
        margin-top: 34px;
        margin-bottom: 44px;
      }
      h5 {
        width: 100%;
        height: 42px;

        font-size: 12px;
        font-weight: 400;
        font-stretch: normal;
        line-height: 30px;
        letter-spacing: 0;
        color: #fff;
      }
      .btn {
        width: 204px;
        height: 48px;
        cursor: pointer;
        font-size: 16px;
        font-stretch: normal;
        letter-spacing: 2px;
        color: #248004;
        background-color: #fff;
        border-radius: 6px;
        border: 1px solid #fff;
        margin-top: 45px;
        margin-left: 12px;
        text-align: center;

        span {
          display: inline-block;
          width: 53px;
          height: 48px;

          background: #248004;
          border-radius: 0 6px 6px 0;
          position: relative;
          bottom: 1px;
          text-align: center;
          vertical-align: middle;
          left: 39px;
          img {
            margin-top: 13px;
            background: #248004;
          }
        }
      }
      .btn:hover {
        background-color: rgba(205, 255, 188, 0.3);
      }
    }
  }
  .imgone-right {
    position: absolute;
    width: 50%;
    right: 0;
    top: 10px;
    height: 900px;
    z-index: 10;
    .imgone-img {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }

    .jingbu {
      position: absolute;
      left: -100px;
      top: 7%;
      .dap {
        font-size: 40px;
      }
      p {
        font-size: 14px;
        color: #fff;
      }
    }
    .qugan {
      position: absolute;
      left: -130px;
      top: 26.5%;
      .dap {
        font-size: 40px;
      }
      p {
        font-size: 14px;
        color: #fff;
      }
    }
    .gupen {
      position: absolute;
      left: -40px;
      top: 53.5%;
      .dap {
        font-size: 40px;
      }
      p {
        font-size: 14px;
        color: #fff;
      }
    }
    .jizhu {
      position: absolute;
      right: -120px;
      top: 23.5%;
      .dap {
        font-size: 40px;
      }
      p {
        font-size: 14px;
        color: #fff;
      }
    }
    .xiguanjie {
      position: absolute;
      right: -120px;
      top: 50.5%;
      .dap {
        font-size: 40px;
      }
      p {
        font-size: 14px;
        color: #fff;
      }
    }
    .zubu {
      position: absolute;
      right: -90px;
      top: 80.5%;
      .dap {
        font-size: 40px;
      }
      p {
        font-size: 14px;
        color: #fff;
      }
    }
  }
}
.imgtwo {
  width: 100%;
  position: relative;
  .imgtwo-left {
    position: absolute;
    right: 100px;
    top: 50%;
    transform: translateY(-50%);
    width: 41%;
    height: 560px;
    z-index: 10;
    border-right: 2px solid hsla(0, 0%, 100%, 0.3);
    border-top: 2px solid hsla(0, 0%, 100%, 0.3);
    border-bottom: 2px solid hsla(0, 0%, 100%, 0.3);
    .biaoyu {
      position: relative;
      width: 12px;
      height: auto;
      font-size: 12px;
      font-weight: 400;
      font-stretch: normal;
      color: hsla(0, 0%, 100%, 0.5);
      float: left;
      text-align: center;
      margin-left: 40px;
      margin-top: 123px;
      .line2 {
        display: inline-block;
        width: 2px;
        height: 62px;
        background: hsla(0, 0%, 100%, 0.5);
      }
      span {
        display: inline-block;
        width: 12px;
        height: 12px;
      }
    }
    .box {
      position: relative;
      margin-top: 60px;
      margin-left: 95px;

      .line1 {
        width: 64px;
        height: 6px;
        background: #fff;
      }
      h1 {
        width: 100%;
        height: 66px;

        font-size: 3.3vw;
        font-stretch: normal;
        letter-spacing: 12px;
        color: #fdfefa;
        font-weight: bolder;
        margin-top: 45px;
      }
      h2 {
        width: 100%;
        height: 30px;

        font-size: 1.5vw;
        font-weight: 400;
        font-stretch: normal;
        letter-spacing: 2px;
        color: #fdfefa;
        margin-top: 34px;
        margin-bottom: 44px;
      }
      h5 {
        width: 100%;
        height: 42px;

        font-size: 12px;
        font-weight: 400;
        font-stretch: normal;
        // line-height: 30px;
        letter-spacing: 0;
        color: #fff;
      }
      .btn {
        width: 204px;
        height: 48px;
        cursor: pointer;
        font-size: 16px;
        font-stretch: normal;
        letter-spacing: 2px;
        color: #248004;
        background-color: #fff;
        border-radius: 6px;
        border: 1px solid #fff;
        margin-top: 45px;
        margin-left: 12px;
        text-align: center;

        span {
          display: inline-block;
          width: 53px;
          height: 48px;

          background: #248004;
          border-radius: 0 6px 6px 0;
          position: relative;
          bottom: 1px;
          text-align: center;
          vertical-align: middle;
          left: 40px;
          img {
            margin-top: 13px;
            background: #248004;
          }
        }
      }
      .btn:hover {
        background-color: rgba(205, 255, 188, 0.3);
      }
    }
  }
  .imgtwo-right {
    position: absolute;
    width: 50%;
    left: 0;
    top: 0px;
    height: 900px;
    z-index: 10;
    .imgtwo-img {
      position: absolute;
      top: 0;
      left: 50%;
      top: 50%;
      width: 45.2vw;
      transform: translate(-50%, -50%);
      img {
        width: 100%;
      }
      .jingbu {
        position: absolute;
        left: 10px;
        top: -40px;
        .dap {
          font-size: 20px;
        }
        p {
          font-size: 12px;
          color: #fff;
        }
      }
      .genzong {
        position: absolute;
        right: -90px;
        top: -40px;
        .dap {
          font-size: 20px;
        }
        p {
          font-size: 12px;
          color: #fff;
        }
      }
      .gugefayu {
        position: absolute;
        right: -50px;
        bottom: -30px;
        .dap {
          font-size: 20px;
        }
        p {
          font-size: 12px;
          color: #fff;
        }
      }
      .jizao {
        position: absolute;
        left: -0px;
        bottom: -30px;
        .dap {
          font-size: 20px;
        }
        p {
          font-size: 12px;
          color: #fff;
        }
      }
    }
  }
}
.home-btn {
  p {
    text-align: center;
    color: #9d9d9d;
  }
  > a {
    display: inline-block;
    width: 100%;
    text-align: center;
    color: #9d9d9d;
  }
  .beian {
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
    a {
      color: #9d9d9d;
    }
  }
}
</style>
